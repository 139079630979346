const reducer = (state = [], { type, payload }) => {
    switch (type) {
  
    case "getOpinions":
      return { ...state, ...payload }
  
    default:
      return state
    }
  }
  
  export default reducer
  