import React, { Fragment, useEffect, useState } from "react";

import "./Home.scss";

import Half from "./subComponents/Half/Half";
import Contact from "../../Components/Contact/Contact";

import img from "./img/home.webp";
import logo from "./img/CzarneBezTla.webp";
import img1 from "./img/half3.webp";
import img5 from "./img/half9.webp";
import img6 from "./img/homenew.jpg";
import imgMobile from './img/mobile.webp'
import { HashLink as Link } from "react-router-hash-link";
import { useSelector } from "react-redux";

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const browser = useSelector((state) => state.browser);
  useEffect(() => {
    if (browser.mediaType === "extraSmall") {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [browser]);
  let styleImg;
  if (!isMobile) {
    styleImg = { backgroundImage: `url(${img})` };
  } else {
    styleImg = null;
  }

  return (
    <div className="home">
        {isMobile ? <img className="mobile_home__img" src={imgMobile} alt="" /> : null}
      <section className="home__img" style={styleImg}>
        <div className="home__scroll-wrapper">
          <div className="home__img-scroll"> </div>
        </div>
      </section>
      <section className="home__about">
        <div className="home__about-logoWrapper">
          <img className="home__about-logo" src={logo} alt="" />
          
        </div>
        
        <h1 className="home__about-text">
          Nazywam się Monika Czerwińska-Łyszczarz,<br />jestem agentką
          nieruchomości. <br /> Pomagam moim klientom spełniać marzenia o nowych
          domach, bezproblemowych najmach i sprawnej sprzedaży nieruchomości.
          <br /> Co mogę zrobić dla Ciebie?
        </h1>
      </section>
      <section>
        <Half
          img={img1}
          left={true}
          text={
            <Fragment>
              <h1>Jeśli planujesz:</h1>
              <p>
                Kupić dom czy mieszkanie, ale nie masz czasu na formalności i
                chcesz dotrzeć do ofert niedostępnych na rynku, powiedz mi,
                jakie są Twoje potrzeby, a ja znajdę Twoje wymarzone lokum.
              </p>
              <p>
                Sprzedać dom czy mieszkanie i nie wiesz, od czego zacząć, zaufaj
                mojej wiedzy i doświadczeniu. Oszczędzę Ci stresu i czasu,
                przeprowadzając przez cały proces sprzedaży.
              </p>
              <p>
                Wynająć dom lub mieszkanie, ale obawiasz się, czy znajdziesz
                uczciwego najemcę, wystarczy, że przekażesz mi klucze, a ja
                dopilnuję, by Twoja nieruchomość trafiła w dobre ręce.
              </p>
            </Fragment>
          }
        />
        <Half
          img={img6}
          left={isMobile ? true : false}
          text={
            <Fragment>
              <h1>Jak działam?</h1>
              <p>
                Na wyłączność – nie uczestniczę w wyścigu po prowizję. Umowa na
                wyłączność oznacza maksimum czasu dla Ciebie, intensywne
                działania marketingowe i dostęp do ofert, zanim pojawią się na
                rynku. To się naprawdę opłaca!
              </p>
              <p>
                Odpowiedzialnie – posiadam polisę ubezpieczeniową pośrednika, a
                to oznacza, że moje zabezpieczenia obejmują też Ciebie. Możesz
                spać spokojnie i nie martwić się o konsekwencje finansowe
                wynikające z luk prawnych czy zawiłości podatkowych.
              </p>
              <p>
                Ze spokojem – nowa nieruchomość to często nowy rozdział w życiu,
                emocje. Działam z empatią i wyczuciem, by uchronić Cię przed
                dodatkowym stresem. Jednocześnie potrafię być twardą
                negocjatorką, która zadba o Twoje finanse.
              </p>
            </Fragment>
          }
        />
        <Half
          img={img5}
          left={true}
          text={
            <Fragment>
              <h1>Czego nie robią moi klienci?</h1>
              <p>
                Nie przedzierają się przez setki ogłoszeń na portalach, <br />
                nie odbierają dziesiątek telefonów każdego dnia,
                <br />
                nie tracą cierpliwości, jeżdżąc po urzędach,
                <br />
                nie ponoszą ukrytych kosztów,
                <br />
                nie stresują się, negocjując ceny,
                <br />
                nie muszą znać się na nieruchomościach, bo to moje zadanie.
              </p>
              <p>
                Chcesz dołączyć do grona zadowolonych klientów? Zacznijmy od
                rozmowy: <br />
                <Link className="link" to="contact#contact">
                  napisz 
                </Link>{" "}
                lub <a href="tel:+ 690 006 924">zadzwoń</a>.
              </p>
              <p>
                Telefon: <a href="tel:+ 690 006 924">690 006 924</a>
                <br />
                <Link className="link" to="contact#contact">
                blizejnieruchomosci@gmail.com
                </Link>
              </p>
            </Fragment>
          }
        />
        <Contact />
      </section>
    </div>
  );
}

export default Home;
