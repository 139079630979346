
import { HashRouter } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Router from "./Router";

function App() {

  return (
    <div className="App">
      <HashRouter>
        <Navbar />
        <Router />
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
