import React, { useEffect, useState } from "react";
import Card from "./Cards/Card";
import "./Blog.scss";
// import MainText from "../../Components/MainText/MainText";
import MainImg from "../../Components/MainImg/MainImg";
import img3 from "../../imgs/3.jpg";
import axios from "axios";

const text =
  "Chcesz zajrzeć do świata nieruchomości widzianego moimi oczami? Tutaj podzielę się z Tobą artykułami, nowinkami i ciekawostkami z mojej branży. ";

function Blog() {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(
        "https://api-bn-img.herokuapp.com/api/blogs?populate=*"
        // "https://api-bn-img.herokuapp.com/api/blogs"
      ).catch((err) => console.log(err));
      setPosts(result);
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isLoading ? (
        <div style={{ height: "100vh" }}>Loading</div>
      ) : (
        <div>
          <MainImg img={img3} text={text} />
          <div className="blog">
            {posts.data.data.map((item) => {
              return (
                <Card
                  key={item.id}
                  item={item}
                  title={item.attributes.Title}
                  text={item.attributes.Text}
                  img={item.attributes.img.data.attributes.formats.medium.url}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Blog;
