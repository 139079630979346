import React from "react";
import { Link } from "react-router-dom";
import "./Card.scss";

function Card({ img, price, title, description, item, history, type }) {
  return (
    <Link
      to={`/estates/${item.id}`}
      style={history ? { pointerEvents: "none" } : null}
      className="wrapper"
      aria-disabled
    >
      <div className="card mb-3">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={img}
              alt="Trendy Pants and Shoes"
              className={
                history
                  ? "img-fluid rounded-start history"
                  : "img-fluid rounded-start"
              }
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5>
                {history ? (price > 100000 ? "Sprzedane" : "Wynajęte") : null}
              </h5>
              <br />
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{description}</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">
                <p className="type">{type}</p>
                {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}zł{" "}
                <br />
              </small>
              <br />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Card;
