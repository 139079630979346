import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import offerReducer from "./offerReducer";
import opinionReducer from "./opinionReducer";
import {responsiveStateReducer} from 'redux-responsive'

const reducers = combineReducers({
  account: accountReducer,
  offer: offerReducer,
  opinion: opinionReducer,
  browser: responsiveStateReducer,
});

export default reducers;
