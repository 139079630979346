import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./MainImg.scss";

function MainImg({ text, img }) {
  const [isMobile, setIsMobile] = useState(false);
  const browser = useSelector((state) => state.browser);
  useEffect(() => {
    if (browser.mediaType === "extraSmall"|| browser.mediaType === "small" || browser.mediaType === "medium") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [browser]);
  return (
    <div>
      <section
        id="top"
        className="main__img"
        style={{ backgroundImage: `url(${img})` }}
      >
        {isMobile ? null : <p className="main-text">{text}</p>}
      </section>
      {isMobile ? <p className="main-text">{text}</p> : null}
    </div>
  );
}

export default MainImg;
