import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Navbar.scss";
import logo from "./img/SamNapisBialy.webp";
import logoMobile from "../../imgs/BialyBezTla.webp";

import { useSelector } from "react-redux";
import { slide as Menu } from "react-burger-menu";

function Navbar() {
  const browser = useSelector((state) => state.browser);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setOpen] = useState(false)
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (browser.mediaType === "extraSmall"|| browser.mediaType === "small" || browser.mediaType === "medium") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [browser]);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const pos = prevScrollpos > currentScrollPos;
    if (currentScrollPos < 100) {
      setPrevScrollpos(currentScrollPos);
      setVisible(true);
    } else {
      setPrevScrollpos(currentScrollPos);
      setVisible(pos);
    }
  };
  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <>
      {isMobile ? (
        <Menu 
    isOpen={isOpen}
    onOpen={handleIsOpen}
    onClose={handleIsOpen}
        width={ '100vw' }>
          <Link onClick={closeSideBar} className="navbar-link menu-item" to="/#top">
            <img src={logoMobile} alt="" />
          </Link>
          <Link onClick={closeSideBar} className="navbar-link" to="team#top">
            Zespół
          </Link>

          <Link onClick={closeSideBar} className="navbar-link" to="estates#top">
            Nieruchomości
          </Link>

          <Link onClick={closeSideBar} className="navbar-link" to="contact#top">
            Kontakt
          </Link>
          {/* <Link className="navbar-logo" to="/#top">
            <img src={logo} alt="" />
          </Link> */}
          <Link onClick={closeSideBar} className="navbar-link" to="blog#top">
            Blog
          </Link>

          <Link onClick={closeSideBar} className="navbar-link" to="offer#top">
            Usługi 
          </Link>
          <Link onClick={closeSideBar} className="navbar-link" to="opinions#top">
            Opinie
          </Link>
        </Menu>
      ) : (
        <nav className={visible ? "navbar" : "navbar hidden"}>
          <Link className="navbar-link" to="team#top">
            Zespół
          </Link>

          <Link className="navbar-link" to="estates#top">
            Nieruchomości
          </Link>

          <Link className="navbar-link" to="contact#top">
            Kontakt
          </Link>
          <Link className="navbar-logo" to="/#top">
            <img src={logo} alt="" />
          </Link>
          <Link className="navbar-link" to="blog#top">
            Blog
          </Link>

          <Link className="navbar-link" to="offer#top">
            Usługi
          </Link>
          <Link className="navbar-link" to="opinions#top">
            Opinie
          </Link>
        </nav>
      )}
    </>
  );
}

export default Navbar;
