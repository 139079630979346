import React from "react";
import arrow from "../../../imgs/arrow.svg";
import arrowUp from "../../../imgs/up.svg";

function Item({ faq, index, toggleFAQ }) {
  return (
    <div
      className={faq.open ? "faq open" : "faq"}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="question">
        {faq.item}
        {faq.open ? (
          <img className="arrow" src={arrowUp} alt="arrow" />
        ) : (
          <img className="arrow" src={arrow} alt="arrow" />
        )}
      </div>
      <div className="answer" dangerouslySetInnerHTML={{ __html: faq.text }} />
    </div>
  );
}

export default Item;
