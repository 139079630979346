import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { marked } from "marked";

import "./ProductPage.scss";
import Contact from "../../Components/Contact/Contact";

function ProductPage() {
  const [item, setItem] = useState(null);
  const params = useParams();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA9vY8d_NDhesnSC4Eewpc2tljjc0bBi6w",
  });
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://api-bn-img.herokuapp.com/api/offers/${params.id}?populate=*`
      ).catch((err) => console.log(err));
      setItem(result.data.data.attributes);
      console.log(result.data.data.attributes);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Map() {
    const center = useMemo(() => ({ lat: item.lat, lng: item.lng }), []);

    return (
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName="map-container"
      >
        <Marker position={center} />
      </GoogleMap>
    );
  }

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      {item ? (
        <div>
          <div className="ProductPage">
            <div className="ProductPage_title">{item.title}</div>
            <div className="ProductPage_top">
              <div className="ProductPage_info">
                <div className="ProductPage_item">
                  {item.floor ? (
                    <>
                      <strong>Piętro: {item.floor} </strong> 
                    </>
                  ) : null}
                </div>
                <div className="ProductPage_item">
                  {item.rooms ? (
                    <>
                      <strong>Liczba pokoi: {item.rooms} </strong> 
                    </>
                  ) : null}
                </div>
                <div className="ProductPage_item">
                  {item.area ? (
                    <strong>
                      Powierzchnia: {item.area} m<sup>2</sup>
                    </strong>
                  ) : null}
                </div>
                <div className="ProductPage_item">
                  {item.landArea ? (
                    <strong>
                      Powierzchnia działki: {item.landArea} m<sup>2</sup>
                    </strong>
                  ) : null}
                </div>
                <div className="ProductPage_item">
                    <strong>Cena: {''}
                    {item.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                    zł
                    </strong>
                </div>
              </div>
              <Carousel className="ProductPage__imgs" showStatus={false} showArrows={true} >
                {item.imgs.data.map((itemImg) => {
                  return (
                    itemImg.attributes.formats.large? 
                    <div
                      className="img_test"
                      key={itemImg.attributes.formats.large.url}
                    >
                      <img
                        src={itemImg.attributes.formats.large.url}
                        alt="test"
                      />
                    </div> : <></>
                  );
                })}
              </Carousel>
            </div>
            <div className="ProductPage_text">
              {parse(marked.parse(item.text))}
            </div>
            <div className="ProductPage_map">
              <h3>Lokalizacja:</h3>
              <Map />
            </div>
          </div>
          <Contact />
        </div>
      ) : (
        <div className="load">Loading</div>
      )}
    </div>
  );
}

export default ProductPage;
