import React, { useState } from "react";
import Item from "./Items/Item.jsx";
import "./Offer.scss"
import MainImg from "../../Components/MainImg/MainImg.jsx";
import img from '../../imgs/offer.jpg'


const text =
  "Zapoznaj się z usługami, które mogę Ci zaoferować i sprawdź czy możemy wspólnie przejść przez transakcje związaną z Twoją nieruchomością.";

function Offer() {
  const [faqs, setFaqs] = useState([
    {
      item: "Zakup",
      text: "</br>Planujesz kupić dom lub mieszkanie, ale nie masz czasu, by przeglądać setki ogłoszeń? </br></br> A może słowa „wydział ksiąg wieczystych” i „negocjacje” skutecznie Cię zniechęcają? </br> </br> Zajmę się wszystkim za Ciebie.</br>  </br> Porozmawiajmy – powiedz mi o swoich oczekiwaniach, a ja znajdę dla Ciebie wymarzoną ofertę. Mam dostęp do ofert niepublikowanych na rynku.</br> Będę towarzyszyć Ci w oglądaniu wybranych nieruchomości, a jeśli nie masz czasu, zrobię to za Ciebie.</br></br> Dopilnuję formalności, kiedy już znajdziesz idealne miejsce. Sprawdzę księgę wieczystą, skonsultuję się ze specjalistą branży budowlanej i architektem, by ocenić ewentualne potrzeby remontowe i nakłady finansowe. A jeśli kupujesz mieszkanie w bloku, skontaktuję się z administracją, by uzyskać informacje np. o planach remontowych, kosztach utrzymania mieszkania itp.</br></br> Kiedy zdecydujesz się na zakup, wynegocjuję dla Ciebie najlepszą cenę. Jeżeli potrzebujesz wsparcia w kwestiach kredytu, również możesz na mnie liczyć.</br></br> Zapewnię Ci wsparcie nawet po podpisaniu aktu notarialnego – zajmę się formalnościami takimi jak przepisanie liczników i umów za media na Ciebie itd.",
      open: true,
    },
    {
      item: "Sprzedaż",
      text: "</br>Sprzedaż nieruchomości pochłania dużo czasu – przygotowania, prezentacje, formalności.</br></br> Nieodłącznym elementem tego są emocje. Jeśli chcesz bez stresu sprzedać swój dom, mieszkanie czy lokal użytkowy, przeprowadzę Cię przez ten proces od początku do końca.</br></br> Zacznę od odpowiedniej wyceny Twojej nieruchomości.</br> Przygotuję nieruchomość do sprzedaży, by prezentowała się jak najlepiej i na zdjęciach, i w czasie prezentacji. Sprawdź też moją ofertę home-stagingu.</br></br> Zajmę się promocją – zorganizuję profesjonalną sesję filmową, przygotuję rzuty pomieszczeń i w razie potrzeby film z drona. Opracuję treść ogłoszenia tak, by wyróżniała się i wzbudzała zainteresowanie.</br></br> Zadbam o działania marketingowe w mediach społecznościowych oraz na portalach dla pośredników nieruchomości.</br></br> Pokażę Twoją nieruchomość potencjalnym nabywcom. Zorganizuję też Dzień Otwarty, który znacznie zwiększa szansę na sprzedaż.</br></br> A kiedy znajdę idealnego nabywcę, pomogę Ci przygotować dokumentację do sprzedaży. Nie musisz martwić się o księgi wieczyste i jeździć po urzędach. To moje zadanie. ",
      open: false,
    },
    {
      item: "Najem",
      text: "</br>Wynajem mieszkania czy domu wymaga cierpliwości – prezentacje, odpowiadanie w kółko na te same pytania, no i obawy o to, czy znajdziesz uczciwego najemcę.</br></br> Jeśli nie masz na to wszystko czasu, przekaż mi klucze, a ja dopnę wszystko na ostatni guzik.</br></br> Zorganizuję sesję zdjęciową, która podkreśli atuty Twojej nieruchomości. </br></br> Zadbam o skuteczną promocję ogłoszenia w mediach społecznościowych oraz na portalach dla pośredników nieruchomości.</br></br> Zaprezentuję Twoją nieruchomość zainteresowanym osobom. </br></br> Zweryfikuję najemcę w specjalnym systemie, dzięki czemu będziesz spać spokojnie. Przygotuję też wszystkie formalności, łącznie z umową najmu okazjonalnego.</br></br> A jeśli zechcesz, odciążę Cię w bieżących sprawach w trakcie najmu. Zobacz moją ofertę zarządzania najmem.",
      open: false,
    },
    {
      item: "Zarządzanie najmem",
      text: "</br>Najem nieruchomości nie kończy się wraz z przekazaniem kluczy.</br></br> Tak naprawdę dopiero wtedy się zaczyna. Zarządzanie płatnościami, nadzór nad stanem nieruchomości, usuwanie ewentualnych usterek to pierwsze z brzegu czynności związane z najmem.</br></br> Nie masz na nie czasu?</br></br> Skorzystaj z usługi zarządzania najmem. </br></br> Zajmę się zarządzaniem opłatami i dopilnuję, by pieniądze regularnie wpływały na Twoje konto.</br></br> W razie usterek zorganizuję fachowców. Cieknący kran czy awaria pieca nie będą odciągać Cię od Twoich spraw.</br></br> Przeprowadzę kontrolę stanu nieruchomości, by zapewnić Cię, że wszystko jest w porządku.",
      open: false,
    },
    {
      item: "Home staging",
      text: "</br>Pierwsze wrażenie możesz zrobić… raz.</br></br> Jeśli nie wiesz, jak wydobyć ze swojej nieruchomości to coś, co sprawi, że potencjalny nabywca szybko zamieni się w mieszkańca, zaufaj mi. Home staging to więcej niż ułożenie kolorowych poduszek na kanapach.</br></br> To usługa, która skraca czas i podnosi wartość transakcji. To opłacalna inwestycja! </br></br> Jeśli nieruchomość tego wymaga, zajmę się odświeżeniem wnętrz, a może nawet remontem, który podniesie atrakcyjność miejsca w oczach potencjalnych klientów. </br></br> Zaaranżuję wnętrza tak, by pokazać w nich przestrzeń i podkreślić zalety. Stworzę domowy klimat i zadbam o każdy detal.</br></br> Dzięki architektowi i specjalistom w moim zespole wiem, jak przeprowadzić metamorfozę Twojej nieruchomości.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div>
      <MainImg img ={img} text={text}/>
      <div className="faqs">
              {faqs.map((faq, i) => (
        <Item faq={faq} index={i} key={i} toggleFAQ={toggleFAQ} />
      ))}
      </div>

    </div>
  );
}

export default Offer;
