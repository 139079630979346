import React from "react";
import "./Card.scss";
import { Link } from "react-router-dom";

function Card({ img, title, text, item}) {
  return (
    <Link
      to={`/blog/${item.id}`}
      className="wrapper"
      aria-disabled
    >
      <div className="card mb-3">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={img}
              alt="Trendy Pants and Shoes"
              className="img-fluid rounded-start"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Card;
