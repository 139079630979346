const reducer = (state = [], { type, payload }) => {
  switch (type) {

  case "apiCall":
    return { ...state, ...payload }

  default:
    return state
  }
}

export default reducer
