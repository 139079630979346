import React from "react";
import "./Half.scss";

function Half({ left, img, text }) {
  return (
    <div>
      {left ? (
        <div className="half-wrapper">
          <div className="half-text">{text}</div>
          <div className="half-img" >
            <img src={img} alt="" />
          </div>
        </div>
      ) : (
        <div className="half-wrapper">
          <div className="half-img" >
            <img src={img} alt="" />
          </div>
          <div className="half-text">{text}</div>
        </div>
      )}
    </div>
  );
}

export default Half;
