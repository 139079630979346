import React, { useEffect, useState } from "react";
import Card from "./Cards/Card";
import "./Estates.scss";
// import img2 from "../Home/img/half8.png";
import MainImg from "../../Components/MainImg/MainImg";

import img3 from "../../imgs/2.jpg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";

const text =
  "Może tylko kilka kliknięć dzieli Cię od wymarzonej nieruchomości? Rozejrzyj się wśród moich ofert. A jeśli żadna z nich nie chwyci Cię za serce, daj znać.\n Z chęcią pomogę Ci w dalszych poszukiwaniach.";

function Estates() {
  const [isLoading, setIsLoading] = useState(true);
  const [offers, setOffers] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(
        "https://api-bn-img.herokuapp.com/api/offers?sort=history&populate=*&pagination[page]=1&pagination[pageSize]=100"
      ).catch((err) => console.log(err));
      const sortedData = result.data.data.sort((a, b) => new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt));
      const sortedData2 = result.data.data.sort((a, b) => new Date(a.attributes.history) - new Date(b.attributes.history));
      console.log(sortedData2)
      setOffers(sortedData);
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      {isLoading ? (
        <div className="load">Loading</div>
      ) : (
        <div>
          <MainImg img={img3} text={text} />
          <div className="estates">
            {offers.map((item) => {
              return (
                <Card
                  key={item.id}
                  item={item}
                  img={item.attributes.imgs.data[0].attributes.formats.medium.url}
                  title={item.attributes.title}
                  text={item.attributes.text}
                  area={item.attributes.area}
                  rooms={item.attributes.rooms}
                  floor={item.attributes.floor}
                  price={item.attributes.price}
                  description={item.attributes.description}
                  history={item.attributes.history}
                  landArea={item.attributes.landArea}
                  type={item.attributes.type}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Estates;
