import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import logo from "./img/BialyBezTla.webp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__contact">
          <img className="footer__logo" src={logo} alt="Logo" />
          <p className="footer__link" >Monika Czerwińska-Łyszczarz</p>
          <a className="footer__link" href="tel:+48690006924">
          +48 690 006 924
          </a>
          <a
            className="footer__link"
            href="mailto:blizejnieruchomosci@gmail.com"
          >
            blizejnieruchomosci@gmail.com
          </a>
          <div className="footer__sm">
            <a href="https://www.instagram.com/blizejnieruchomosci/">
              <FontAwesomeIcon icon={faInstagram} className="icon" />
            </a>
            <a href="https://www.facebook.com/Bliżej-nieruchomości-113913691071621">
              <FontAwesomeIcon icon={faFacebookSquare} className="icon" />
            </a>
          </div>
        </div>
        <div className="footer__wrapper-links">
          <Link className="footer__link" to="team">
            Zespół
          </Link>
          <Link className="footer__link" to="estates">
            Nieruchomości
          </Link>
          <Link className="footer__link" to="contact">
            Kontakt
          </Link>
          <Link className="footer__link" to="blog">
            Blog
          </Link>
          <Link className="footer__link" to="offer">
            Usługi
          </Link>
          <Link className="footer__link" to="opinions">
            Opinie
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
