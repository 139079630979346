import React from "react";

function Arrows({nextQuote, prevQuote}) {
  return (
    <div>
      <p onClick={prevQuote} className="prev" id="prev">
        &#10094;
      </p>
      <p onClick={nextQuote} className="next" id="next">
        &#10095;
      </p>
    </div>
  );
}

export default Arrows;
