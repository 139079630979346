import React, { useEffect, useState } from "react";
import "./Team.scss";
import monika from "./img/monika2.jpg";
import ewa from "./img/Marczak Finanase.png";
// import piotr from "./img/Piotr Wujtko.jpg";
import lidka from "./img/Lidka.jpg";
import magda from "./img/Magda.jpg";
import kacper from "./img/Kacper.png";
import MainImg from "../../Components/MainImg/MainImg";

import img from "../../imgs/1.jpg";
import { useSelector } from "react-redux";

const text =
  "Mój zespół to eksperci i… fajni ludzie – architekt, fotografowie, specjaliści od ubezpieczeń, kredytów czy spraw budowlanych. Razem zaopiekujemy się Twoją nieruchomością od a do z.";

function Team() {
  const [isMobile, setIsMobile] = useState(false);
  const browser = useSelector((state) => state.browser);
  useEffect(() => {
    if (browser.mediaType === "extraSmall") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [browser]);
  return (
    <div className="team">
      <MainImg img={img} text={text} />
      <div className="team__monika">
        {isMobile ? null : (
          <img className="team__monika--img" src={monika} alt="" />
        )}
        <div className="team__monika--text">
          <h1>Monika Czerwińska–Łyszczarz</h1>
          {isMobile ? (
            <img className="team__monika--img" src={monika} alt="" />
          ) : null}
          <p>
            Bywa że ścieżka zawodowa niektórych osób jest dziełem przypadku. Ja
            wolę wierzyć w przeznaczenie! Miałam szczęście wkroczyć w świat
            nieruchomości w jednym z najbardziej prestiżowych biur w Poznaniu.
            Zaczynałam od stanowiska asystentki, byłam też managerką biura, ale
            tak naprawdę fascynowała mnie praca innych agentek.
          </p>
          <p>
            Pamiętam, jak szefowa powiedziała mi, że jeśli pozyskam samodzielnie
            pierwszego klienta, da mi szansę na tym stanowisku. I tak też się
            stało, bo tego samego dnia do biura wszedł mój pierwszy klient, z
            którym współpracuję do dziś. Myślę, że to był znak, że branża
            nieruchomości jest dla mnie.
          </p>
          <p>
            I tak zaczął się czas intensywnych szkoleń – prawnych, związanych z
            obsługą klientów, negocjacjami i tworzeniem atrakcyjnych ofert. To
            wszystko w połączeniu z doświadczeniem i wiedzą, jak funkcjonuje
            agencja nieruchomości, utwierdziło mnie w przekonaniu, że jestem
            gotowa podążać własną ścieżką. Piszę te słowa, będąc właścicielką
            agencji Bliżej nieruchomości.
          </p>
          <p>
            Przestrzeń poza pracą wypełniają mi studia psychologiczne, które
            świetnie łączą się z moim zawodem. Kocham aktywny wypoczynek –
            jeżdżę konno, biegam, haftuję i zajmuję się… 3 kotami i psem.
            Uwielbiam życie na pełnych obrotach, ale po intensywnym dniu
            niesamowicie cenię sobie możliwość spędzenia czasu w przytulnym
            domu. Moją misją jest znalezienie każdemu klientowi takiego miejsca,
            na myśl o którym będzie robiło mu się ciepło w sercu.
          </p>
        </div>
      </div>
      <div className="team__else">
        <div className="team__else--item">
          {isMobile ? null : (
            <img src={kacper} alt="" className="team__else--ig" />
          )}

          <div className="team__else--name">
            <h1>Kacper Łyszczarz</h1>
            <h2>Agent nieruchomości</h2>
            {isMobile ? (
              <img src={kacper} alt="" className="team__else--ig" />
            ) : null}
            <div className="team__else--text">
              <p>
                Do swojej pracy podchodzę z wielką misją i odpowiedzialnością, a
                to dlatego, że na co dzień jestem także strażakiem. Tutaj
                codziennie spotykam się z ludźmi, emocjami i sytuacjami, w
                których trzeba podejmować sprawne i bardzo wyważone decyzje.
                Obserwując prace biura nieruchomości z boku od lat zrozumiałem,
                że to praca do której mogę przenieść moje umiejętności i
                sprawdzić się jako rzetelny doradca. Aktualnie cały czas się
                szkolę i rozwijam, aby móc obdarzyć moich klientów profesjonalną
                opieką i aby czuli się bezpiecznie dokunując transkacji pod
                moimi skrzydłami.
              </p>
              <p>
                Prywatnie kocham sport pod każdą postacią: piłka ręczna, squash,
                tenis, pływanie - to wszystko sprawia mi ogromną
                przyjemność. Konsekwencja, która towarzyszy mi w uprawianiu
                sportów towarzyszy mi również w pracy. Jestem także fanem
                podróżowania i planowania wycieczek z mapą i nawigajcą.
                Podróżując ze mną na pewno się nie zgubicie
              </p>
            </div>
          </div>
        </div>
        <div className="team__else--item">
          {isMobile ? null : (
            <img src={ewa} alt="" className="team__else--ig" />
          )}
          <div className="team__else--name">
            <h1>Ewa i Sławek Marczak</h1>
            <h2>Marczak Finanse</h2>
            {isMobile ? (
              <img src={ewa} alt="" className="team__else--ig" />
            ) : null}
            <div className="team__else--text">
              <p>
                Prowadzimy rodzinnę firmę, która wspiera realizację marzeń o
                własnym m. Dobrze wiemy, że wiedza o aktualnej ofercie banków i
                możliwościach finansowania jest dla klientów trudna do
                opanowania. Dlatego od ponad 15 lat sprawnie przeprowadzamy
                klientów przez proces finansowania zakupu nieruchomości,
                dobierając najlepsze rozwiązania finansowe.
              </p>
              <p>
                Nieustannie się szkolimy, jesteśmy na bieżąco z ofertą kredytów
                hipotecznych, mamy za sobą setki zrealizowanych transakcji.
                Jeśli potrzebujesz wsparcia przy zakupie nieruchomości, jesteśmy
                do usług.
              </p>
            </div>
          </div>
        </div>
        <div className="team__else--item">
          {isMobile ? null : (
            <img src={lidka} alt="" className="team__else--ig" />
          )}
          <div className="team__else--name">
            <h1>Lidia Ostrowska</h1>
            <h2>Studio Miasto</h2>
            {isMobile ? (
              <img src={lidka} alt="" className="team__else--ig" />
            ) : null}
            <div className="team__else--text">
              <p>
                Z wykształcenia jestem urbanistką, a z zamiłowania i zawodu
                fotografką. Z powodzeniem łączę obydwie dziedziny, fotografując
                głównie wnętrza i architekturę. Od kilku lat związana jestem z
                branżą deweloperską i nieruchomości. Uwielbiam otaczać się
                ludźmi, więc również w ich stronę kieruję swój obiektyw.
              </p>
              <p>
                Prywatnie jestem miłośniczką roślin doniczkowych, psów i
                planszówek. W wolnych chwilach biorę udział w rozgrywkach laser
                tag, a po godzinach poszerzam swoją wiedzę o Poznaniu, aspirując
                na przewodniczkę po tym mieście.{" "}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="team__else--item">
          {isMobile ? null : (
            <img src={piotr} alt="" className="team__else--ig" />
          )}
          <div className="team__else--name">
            <h1>Piotr Wujtko</h1>
            <h2>Fotografia</h2>
            {isMobile ? (
              <img src={piotr} alt="" className="team__else--ig" />
            ) : null}
            <div className="team__else--text">
              <p>
                Mój pierwszy profesjonalny aparat fotograficzny kupiłem w
                grudniu 2000 r. w Nowym Jorku, a pierwsze profesjonalne zdjęcie
                zrobiłem na Brooklyn Bridge. Chodząc wówczas po mieście z
                aparatem, nieśmiało marzyłem, że może kiedyś zostanę
                fotografem...
              </p>
              <p>
                Tak też się stało. Specjalizuję się w fotografii nieruchomości.
                Dzięki licencjom NSTS-09 i BVLOS mogę latać dronem nad obiektami
                komercyjnymi, nad ludźmi, nawet w strefach zagrożonych i
                niebezpiecznych. Ukończyłem prestiżową Matterport Academy w
                Niemczech. Wykonuję spacery wirtualne w technologii 3D, używając
                skanera Matterport.
              </p>
              <p>
                W mojej pracy stawiam na jakość w każdym tego słowa znaczeniu.
                Ciągle poszerzam swoją wiedzę, uczę się, rozwijam swój
                warsztat... W efekcie tworzę świadomą fotografię.
              </p>
            </div>
          </div>
        </div> */}
        <div className="team__else--item">
          {isMobile ? null : (
            <img src={magda} alt="" className="team__else--ig" />
          )}
          <div className="team__else--name">
            <h1>Magda Bachlaj</h1>
            <h2>Projektant wnętrz</h2>
            {isMobile ? (
              <img src={magda} alt="" className="team__else--ig" />
            ) : null}
            <div className="team__else--text">
              <p>
                Jestem projektantką wnętrz: moją główną misją jest to, aby
                marzenia klientów o pięknej przestrzeni zamienić w
                rzeczywistość, okrasić je jak największą funkcjonalnością i
                ergonomią, bez straty w finalnym wizualnym efekcie oraz z
                uwzględnieniem planowanego budżetu 🙂
              </p>
              <p>
                Swoją drogę z projektowaniem zaczynałam od planowania kuchni i
                mebli na wymiar w jednej z większych polskich firm. Dzisiaj ta
                wiedza pomaga mi w tworzeniu dla Was praktycznych i pięknych
                wnętrz: od salonu, przez kuchnię, pokój dziecięcy, sypialnię, po
                łazienkę!
              </p>
              <p>
                Prywatnie jestem mamą wesołego dwulatka i dwóch szalonych kotów.
                Wolny czas spędzam na wolontariacie w Stowarzyszeniu Pokotaj,
                jedzeniu pysznych rzeczy i czytaniu dobrych książek.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
