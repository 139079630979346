import React from "react";
import { Route, Routes } from "react-router";
import Contact from "./Components/Contact/Contact";
import MainImg from "./Components/MainImg/MainImg";
import Blog from "./Views/Blog/Blog";
import Estates from "./Views/Estates/Estates";
import Home from "./Views/Home/Home";
import Offer from "./Views/Offer/Offer";
import Opinions from "./Views/Opinions/Opinions";
import Team from "./Views/Team/Team";
import img from "./imgs/contact.jpg";
import ProductPage from "./Views/ProductPage/ProductPage";

const text =
  "Zacznijmy od rozmowy. Jeśli interesuje Cię zakup, sprzedaż lub najem nieruchomości, chętnie odpowiem na Twoje pytania.";

function Router() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="contact"
          element={
            <div>
              <MainImg img={img} text={text} />
              <Contact />
            </div>
          }
        />
        <Route path="estates" element={<Estates />} />
        <Route path="blog" element={<Blog />} />
        <Route path="offer" element={<Offer />} />
        <Route path="opinions" element={<Opinions />} />
        <Route path="team" element={<Team />} />
        <Route path={`estates/:id`} element={<ProductPage />} />
        <Route path={`blog/:id`} element={<ProductPage />} />
      </Routes>
    </div>
  );
}

export default Router;
