import React from "react";
import logo from "../../Home/img/CzarneBezTla.webp"

function Item({ quote }) {
  return (
    <div className="opinions__wrapper">
      <img className="opinions__img" src={logo} alt="Logo" />
      <p className="opinions__text">{quote.attributes.author}</p>
      <p className="opinions__author">{quote.attributes.text}</p>
    </div>
  );
}

export default Item;
