import React, { useEffect, useState } from "react";
import Item from "./Sub/Item";
import "./Opininos.scss";
import Arrows from "./Sub/Arrows";
import Dots from "./Sub/Dots";
import axios from "axios";
import MainImg from "../../Components/MainImg/MainImg";
import img3 from "../../imgs/opinion.png";

const text = "Bliżej nieruchomości oznacza dla mnie… bliżej ludzi. Cieszę się, gdy klienci doceniają moje działania i atmosferę współpracy. Poznaj opinie osób, którym pomogłam.";
function Opinions() {
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [opinions, setOpinions] = useState([]);
  const [quote, getQuote] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      axios("https://api-bn-img.herokuapp.com/api/opinions")
        .then((response) => setOpinions(response))
        .catch((err) => console.log(`error${err}`))
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (opinions.data) {
      getQuote(opinions.data.data[current]);
    }
  }, [current, quote, opinions]);

  useEffect(() => {
    setTimeout(() => {
      slider();
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, current]);

  const slider = () => {
    if (!isLoading) {
      if (current === opinions.data.data.length - 1) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    }
  };

  const nextQuote = () => {
    if (!isLoading) {
      current === opinions.data.data.length - 1
        ? setCurrent(0)
        : setCurrent(current + 1);
    }
  };

  const prevQuote = () => {
    current === 0
      ? setCurrent(opinions.data.data.length - 1)
      : setCurrent(current - 1);
  };

  const dotPicksQuote = (e) => setCurrent(Number(e.target.id));

  return (
    <div>
      <div>
        {isLoading ? (
          <div style={{ height: "100vh" }} className="load">Loading</div>
        ) : (
          <div>
            <MainImg img={img3} text={text} />
            <div className="opinions">
              
              <Item quote={quote} />
              <Arrows nextQuote={nextQuote} prevQuote={prevQuote} />

              <Dots
                dotQty={opinions.data.data}
                current={current}
                dotPicksQuote={dotPicksQuote}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Opinions;
