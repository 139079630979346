import React, { useEffect, useState } from "react";
import bemCssModules from "bem-css-modules";
import emailjs from "emailjs-com";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faViber,
} from "@fortawesome/free-brands-svg-icons";

import { default as ContactStyles } from "./Contact.module.scss";
import img from "./img/contact.webp";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const style = bemCssModules(ContactStyles);

const Contact = () => {

  const [isMobile, setIsMobile] = useState(false);
  const browser = useSelector((state) => state.browser);
  useEffect(() => {
    if (browser.mediaType === "medium" || browser.mediaType === "small" || browser.mediaType === "extraSmall") {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [browser]);

  const [state, setState] = useState({
    name: "",
    mail: "",
    text: "",
  });
  const [errors, setErrors] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!state["name"]) {
      setErrors("Podaj swoje imię");
    } else if (!state["mail"]) {
      setErrors("Podaj swój mail");
    } else if (!state["text"]) {
      setErrors("Wprowadz teks wiadomości");
    } else
      emailjs.sendForm("service_vumva9l", "template_n24cfuc", e.target, "user_ux3gKfqC84EoWJjDiNkqL").then(
        (result) => {
          setState({ name: "", mail: "", text: ""});
          setErrors("Dziękuje za wysłanie wiadomości");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const onInputChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <section id="contact" className={style()}>
      {isMobile ? null : (
        <div className={style("ImgWrapper")}>
          <div className={style("iconWrapper")}>
            <a href="https://www.instagram.com/blizejnieruchomosci/">
              <FontAwesomeIcon icon={faInstagram} className={style("icon")} />
            </a>
            <a href="https://www.facebook.com/Bliżej-nieruchomości-113913691071621">
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={style("icon")}
              />
            </a>
            <a href="tel:+">
              <FontAwesomeIcon icon={faViber} className={style("icon")} />
            </a>
          </div>

          <img src={img} alt="" className={style("image")} />
        </div>
      )}

      <div ref={(el) => {}} className={style("inlineStyle")}>
        <div>
          <h1 className={style("title")}>Napisz do mnie</h1>
          <h1 className={style("description")}>
            Zacznijmy od rozmowy. Jeśli interesuje Cię zakup, sprzedaż lub najem
            nieruchomości, chętnie odpowiem na Twoje pytania.
          </h1>
        </div>
        <form onSubmit={handleOnSubmit}>
          <h2 className={style("errors")}>{errors}</h2>
          <input
            className={style("input")}
            placeholder="Podaj swoje imię"
            name="name"
            type="text"
            value={state.name}
            onChange={onInputChange}
          />
          <input
            className={style("input")}
            placeholder="Podaj swój adres e-mail"
            name="mail"
            type="email"
            value={state.mail}
            onChange={onInputChange}
          />
          <textarea
            className={style("textarea")}
            rows="5"
            name="text"
            type="text"
            placeholder="Napisz w kilku słowach jak mogę Ci pomóc."
            value={state.text}
            onChange={onInputChange}
          />

          <button className={style("subBtn")}>WYŚLIJ WIADOMOŚĆ</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
