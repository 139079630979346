import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { composeWithDevTools } from "@redux-devtools/extension";
import {responsiveStoreEnhancer} from 'redux-responsive'

export const store = createStore(
  reducers,
  {},
  composeWithDevTools(responsiveStoreEnhancer,applyMiddleware(thunk))
);
